<template>
  <div class="contactblock">
    <div class="contactblock__wrapper">
      <div class="contactblock__content">
        <div class="contactblock__animation"><ContactBlockAnimation /></div>
        <div class="block__text block__text--2">
          <a :href="getMail" class=""> {{ kontakt.email }}</a
          ><br />
          <a :href="getPhone" class="">{{ kontakt.phone }}</a>
        </div>
        <br />
        <div class="block__text">
          Wir sehen unsere Kunden als Tanzpartner und legen eine heiße Sohle
          aufs Parkett.
        </div>
        <div class="block__p">
          Denn tanzen ist das perfekte Zusammenspiel zweier Individuen. Oder
          anders: ein Erfolgreiches Projekt. Wir tanzen Breakdance auf
          Hochzeiten und Wiener Walzer im Club. Dürfen wir bitten?
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactInformation from "@/utilities/siteinformation/contactInformation";

import ContactBlockAnimation from "./ContactBlockAnimation.vue";
export default {
  components: { ContactBlockAnimation },
  setup() {
    const kontakt = contactInformation;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;

    return { kontakt, getMail, getPhone };
  },
};
</script>

<style lang="scss">
.contactblock__wrapper {
  position: relative;
  width: 100%;
}
</style>
