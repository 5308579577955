<template>
  <div
    class="comingsoon"
    :class="{ showComing: show, showComing: show }"
    @click.prevent="show = true"
    @mouseenter="show = true"
    @mouseleave="show = false"
  >
    <div class="comingsoon__bg"></div>

    <svg
      version="1.1"
      class="comingsoon__svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 188.7 20"
      style="enable-background:new 0 0 188.7 20;"
      xml:space="preserve"
    >
      <g>
        <g id="Gruppe_748" transform="translate(-1047.5 -3418.004)">
          <g class="comingsoon__st0">
            <path
              class="comingsoon__st1"
              d="M1065.1,3433.9c-0.5,0.6-1.2,1.2-2,1.7c-0.6,0.4-1.5,0.8-2.4,1.2c-1,0.3-2.1,0.5-3.4,0.5
				c-1.4,0-2.8-0.2-4-0.7c-1.2-0.5-2.2-1.1-3.1-2c-0.9-0.8-1.6-1.8-2.1-2.9s-0.7-2.3-0.7-3.6s0.2-2.5,0.7-3.6s1.2-2.1,2.1-2.9
				s1.9-1.5,3.1-2c1.2-0.5,2.5-0.7,4-0.7c1.3,0,2.4,0.2,3.4,0.5c0.9,0.3,1.7,0.7,2.4,1.2c0.8,0.5,1.4,1.1,1.9,1.7l-3.4,3.6
				c-0.3-0.4-0.7-0.7-1.1-1c-0.3-0.2-0.8-0.5-1.3-0.7c-0.5-0.2-1.1-0.3-1.8-0.3c-0.6,0-1.2,0.1-1.8,0.3c-0.5,0.2-1,0.5-1.4,0.9
				c-0.4,0.4-0.7,0.8-0.9,1.4s-0.3,1.1-0.3,1.7s0.1,1.2,0.3,1.7s0.5,1,0.9,1.4c0.4,0.4,0.8,0.7,1.4,0.9c0.5,0.2,1.1,0.3,1.8,0.3
				c0.6,0,1.2-0.1,1.8-0.3c0.5-0.2,1-0.4,1.3-0.7c0.4-0.3,0.8-0.6,1.1-1L1065.1,3433.9z"
            />
            <path
              class="comingsoon__st1"
              d="M1066,3428c0-1.3,0.2-2.5,0.7-3.6s1.2-2.1,2.1-2.9s1.9-1.5,3.1-2c1.2-0.5,2.5-0.7,4-0.7c1.4,0,2.8,0.2,4,0.7
				c1.2,0.5,2.2,1.1,3.1,2s1.6,1.8,2.1,2.9c0.5,1.1,0.7,2.3,0.7,3.6s-0.2,2.5-0.7,3.6c-0.5,1.1-1.2,2.1-2.1,2.9
				c-0.9,0.8-1.9,1.5-3.1,2c-1.2,0.5-2.5,0.7-4,0.7c-1.4,0-2.8-0.2-4-0.7c-1.2-0.5-2.2-1.1-3.1-2c-0.9-0.8-1.6-1.8-2.1-2.9
				S1066,3429.3,1066,3428z M1071.6,3428c0,0.6,0.1,1.2,0.3,1.7c0.2,0.5,0.5,1,0.9,1.4c0.4,0.4,0.8,0.7,1.4,0.9
				c0.5,0.2,1.1,0.3,1.7,0.3s1.2-0.1,1.7-0.3c0.5-0.2,1-0.5,1.4-0.9c0.4-0.4,0.7-0.8,0.9-1.4s0.3-1.1,0.3-1.7s-0.1-1.2-0.3-1.7
				s-0.5-1-0.9-1.4c-0.4-0.4-0.8-0.7-1.4-0.9s-1.1-0.3-1.7-0.3s-1.2,0.1-1.7,0.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.7,0.8-0.9,1.4
				C1071.7,3426.8,1071.6,3427.4,1071.6,3428z"
            />
            <path
              class="comingsoon__st1"
              d="M1088.8,3419.3h5l4.9,6.2l4.9-6.2h5v17.5h-5.6v-9.4l-4.2,5.4l-4.2-5.4v9.4h-5.6V3419.3z"
            />
            <path
              class="comingsoon__st1"
              d="M1112.5,3419.3h5.6v17.5h-5.6V3419.3z"
            />
            <path
              class="comingsoon__st1"
              d="M1122.1,3419.3h4.9l6.5,8.4v-8.4h5.6v17.5h-4.9l-6.5-8.4v8.4h-5.6V3419.3z"
            />
            <path
              class="comingsoon__st1"
              d="M1151.7,3427h8.1v6.8c-0.6,0.7-1.2,1.2-2,1.8c-0.7,0.4-1.5,0.8-2.5,1.2c-1,0.4-2.1,0.5-3.4,0.5
				c-1.4,0-2.8-0.2-4-0.7c-1.2-0.5-2.2-1.1-3.1-2c-0.9-0.8-1.6-1.8-2.1-2.9s-0.7-2.3-0.7-3.6s0.2-2.5,0.7-3.6s1.2-2.1,2.1-2.9
				s1.9-1.5,3.1-2c1.2-0.5,2.5-0.7,4-0.7c1.3,0,2.4,0.2,3.4,0.5c0.9,0.3,1.7,0.7,2.4,1.2c0.8,0.5,1.4,1.1,1.9,1.7l-3.4,3.6
				c-0.3-0.4-0.7-0.7-1.1-1c-0.3-0.2-0.8-0.5-1.3-0.7c-0.5-0.2-1.1-0.3-1.8-0.3c-0.6,0-1.2,0.1-1.8,0.3c-0.5,0.2-1,0.5-1.4,0.9
				c-0.4,0.4-0.7,0.8-0.9,1.4s-0.3,1.1-0.3,1.7s0.1,1.2,0.3,1.7c0.2,0.5,0.5,1,0.9,1.4c0.4,0.4,0.8,0.7,1.4,0.9
				c0.5,0.2,1.1,0.3,1.7,0.3c0.5,0,0.9,0,1.2-0.1c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.2,0.7-0.4v-1.4h-3V3427z"
            />
            <path
              class="comingsoon__st1"
              d="M1170.7,3430.9c0.3,0.3,0.6,0.6,1,0.9c0.3,0.2,0.7,0.4,1.1,0.6c0.4,0.2,0.9,0.3,1.4,0.3
				c0.6,0,1.1-0.1,1.4-0.3c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.2-0.6-0.6-0.8c-0.4-0.2-0.9-0.4-1.5-0.7c-0.6-0.2-1.2-0.5-1.9-0.8
				c-0.7-0.3-1.3-0.6-1.9-1s-1.1-1-1.5-1.6c-0.4-0.6-0.6-1.4-0.6-2.3c0-0.7,0.2-1.4,0.5-2.1c0.3-0.7,0.8-1.3,1.4-1.8
				c0.6-0.5,1.3-0.9,2.2-1.2s1.9-0.5,3-0.5c1.1,0,2.1,0.1,2.9,0.4c0.8,0.3,1.5,0.5,2,0.9c0.6,0.4,1.1,0.8,1.6,1.2l-3.2,3.4
				c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.7-0.2-1.1-0.2c-0.5,0-0.9,0.1-1.1,0.2c-0.2,0.2-0.4,0.4-0.4,0.6
				c0,0.3,0.2,0.6,0.6,0.8c0.4,0.2,0.9,0.4,1.5,0.7c0.6,0.2,1.2,0.5,1.9,0.8c0.7,0.3,1.3,0.6,1.9,1c0.6,0.4,1.1,1,1.5,1.6
				c0.4,0.6,0.6,1.4,0.6,2.3c0,0.8-0.2,1.5-0.5,2.2c-0.3,0.7-0.8,1.3-1.4,1.8s-1.4,1-2.3,1.3s-1.9,0.5-3,0.5c-1.2,0-2.3-0.2-3.2-0.5
				c-0.9-0.3-1.7-0.7-2.3-1c-0.7-0.4-1.4-0.9-1.9-1.5L1170.7,3430.9z"
            />
            <path
              class="comingsoon__st1"
              d="M1219.2,3419.3h4.9l6.5,8.4v-8.4h5.6v17.5h-4.9l-6.5-8.4v8.4h-5.6V3419.3z"
            />
          </g>
        </g>
        <g>
          <g id="Gruppe_746" transform="translate(1236 3423)">
            <ellipse
              id="Ellipse_1"
              class="comingsoon__st1"
              cx="-1092"
              cy="-3413"
              rx="8"
              ry="10"
            />
            <ellipse
              id="Ellipse_3"
              class="comingsoon__st2"
              cx="-1091.5"
              cy="-3413"
              rx="3.5"
              ry="4"
            />
            <circle
              id="Ellipse_4"
              class="comingsoon__st1"
              cx="-1090"
              cy="-3415"
              r="2"
            />
          </g>
          <g id="Gruppe_747" transform="translate(1254.074 3423)">
            <ellipse
              id="Ellipse_2"
              class="comingsoon__st1"
              cx="-1092.1"
              cy="-3413"
              rx="8"
              ry="10"
            />
            <ellipse
              id="Ellipse_6"
              class="comingsoon__st2"
              cx="-1091.6"
              cy="-3413"
              rx="3.5"
              ry="4"
            />
            <circle
              id="Ellipse_5"
              class="comingsoon__st1"
              cx="-1090.1"
              cy="-3415"
              r="2"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    const show = ref(false);
    return {
      show,
    };
  },
};
</script>
<style type="text/css" lang="scss">
.comingsoon__st0 {
  enable-background: new;
}
.comingsoon__st1 {
  fill: #ffffff;
}
.comingsoon__st2 {
  fill: #010202;
}

.comingsoon {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;

  transition: 0.2s;
  &.showComing {
    opacity: 1;
  }
}

.comingsoon__bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(60, 60, 60);
  opacity: 0.5;
  z-index: 0;
}
.comingsoon__svg {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  max-width: 150px;
}
</style>
